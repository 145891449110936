import { Entities } from '@inwink/entities/entities';

export function transformWorkTemplateFields(workTemplateFields: Record<string, Entities.IEntityFieldTemplateV3>) {
    const fieldsObj = {};
    const fieldsArray = [];

    Object.keys(workTemplateFields).forEach((key) => {
        const workTemplateField = workTemplateFields[key];
        const field: Entities.IEntityFieldTemplate = Object.assign({}, workTemplateField as any);
        fieldsObj[key] = field;
        fieldsArray.push(field);
        if (workTemplateField.display) {
            if (workTemplateField.display.labels) field.labels = workTemplateField.display.labels;
            if (workTemplateField.display.descriptions) field.descriptions = workTemplateField.display.descriptions;
            if (workTemplateField.display.placeholders) field.placeholders = workTemplateField.display.placeholders;
        }

        if (workTemplateField.metadata) {
            if (workTemplateField.metadata.isStatic) field.isStatic = workTemplateField.metadata.isStatic;
            if (workTemplateField.metadata.isLocked) field.isLocked = workTemplateField.metadata.isLocked;
            if (workTemplateField.metadata.type) field.type = workTemplateField.metadata.type;
            if (workTemplateField.metadata.isLocalizable) field.isLocalizable = workTemplateField.metadata.isLocalizable;
            if (workTemplateField.metadata.applyTo) field.applyTo = workTemplateField.metadata.applyTo;
            if (workTemplateField.metadata.readOnly) field.isReadOnly = workTemplateField.metadata.readOnly;
            if (workTemplateField.metadata.isCollection) field.isCollection = workTemplateField.metadata.isCollection;
            if (workTemplateField.metadata.aiAssistantContext) 
                field.aiAssistantContext = workTemplateField.metadata.aiAssistantContext;
        }

        if (workTemplateField.file) {
            if (workTemplateField.file.isPublicFile) field.isPublicFile = workTemplateField.file.isPublicFile;
            if (workTemplateField.file.maxSize) field.maxSize = workTemplateField.file.maxSize;
            if (workTemplateField.file.mimeTypes) field.mimeTypes = workTemplateField.file.mimeTypes;
        }

        if (workTemplateField.validation) {
            if (workTemplateField.validation.isMandatory) field.isMandatory = workTemplateField.validation.isMandatory;
            if (workTemplateField.validation.isMandatoryFor) field.isMandatoryFor = workTemplateField.validation.isMandatoryFor;
            if (workTemplateField.validation.min) field.min = workTemplateField.validation.min;
            if (workTemplateField.validation.max) field.max = workTemplateField.validation.max;
            if (workTemplateField.validation.regex) field.value = workTemplateField.validation.regex;
        }

        if (workTemplateField.nestedEntity) {
            field.nestedEntity = transformWorkTemplateFields(workTemplateField.nestedEntity).map;
        }
    });

    return {
        map: fieldsObj,
        array: fieldsArray
    };
}

export function transformWorkTemplate(workTemplate: Entities.IEntityTemplateV3): Entities.IEntityTemplate {
    const et: Entities.IEntityTemplate = {
        scopes: workTemplate.scopes,
        languages: (workTemplate as any).languages,
        oldKeys: workTemplate.oldKeys,
        fields: transformWorkTemplateFields(workTemplate.fields).array
    };

    return et;
}
