/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { Entities } from '@inwink/entities/entities';

import './personbubble.less';

export interface IPersonBubbleProps {
    person: Entities.IPerson;
    className?: string;
    onClick?: (arg) => void;
}

export class PersonBubble extends React.PureComponent<IPersonBubbleProps, any> {
    constructor(props: IPersonBubbleProps) {
        super(props);
        this.state = {
            fullname: null,
            picture: null
        };
    }

    static getDerivedStateFromProps(props: IPersonBubbleProps, state: any) {
        const newstate = Object.assign({}, state);
        if (props.person && props.person.photo) {
            let photoUrl = null;
            if (typeof props.person.photo === 'string') {
                photoUrl = props.person.photo;
            } else {
                const photoObj = props.person.photo as Entities.IExtendedFieldsFile;
                if (photoObj && photoObj.url) {
                    photoUrl = photoObj.thumbUrl ? photoObj.thumbUrl : photoObj.url;
                }
            }

            if (photoUrl) {
                newstate.picture = photoUrl;
            }
        } else {
            newstate.picture = "";
        }

        if (props.person) {
            let fullname = props.person.mail;
            if ((props.person.firstname || props.person.lastname)) {
                fullname = (props.person.firstname || "") + " " + (props.person.lastname || "");
            }

            if (fullname) {
                newstate.fullname = fullname.trim();
            }
        }

        return newstate;
    }

    render() {
        return <FullNameBubble
            fullname={this.state.fullname}
            picture={this.state.picture}
            className={this.props.className}
            onClick={this.props.onClick}
        />;
    }
}

export interface IFullNameBubbleProps {
    id?: string;
    fullname: string;
    picture?: string;
    className?: string;
    onClick?: (arg) => void;
    tooltip?: string;
}

export interface IFullNameBubbleState {
    initials: string;
    photo: any;
}

export class FullNameBubble extends React.PureComponent<IFullNameBubbleProps, IFullNameBubbleState> {
    constructor(props: IFullNameBubbleProps) {
        super(props);
        this.state = {
            initials: null,
            photo: null
        };
    }

    static getDerivedStateFromProps(props: IFullNameBubbleProps, state: IFullNameBubbleState) {
        const newstate = Object.assign({}, state);

        if (props.fullname) {
            const tmp = [];
            props.fullname.split(" ").forEach((s) => {
                if (s) {
                    tmp.push(s[0].toUpperCase());
                }
            });
            newstate.initials = tmp.slice(0, 3).join("");
        }

        if (props.picture) {
            newstate.photo = {
                backgroundImage: `url("${props.picture}")`
            };
        } else {
            newstate.photo = "";
        }
        return newstate;
    }

    render() {
        const { tooltip } = this.props;
        let blocPhoto;
        if (this.state.photo) {
            // blocPhoto = <div className="photo" style={this.state.photo} />;
            blocPhoto = <img className="photo" src={this.props.picture} loading="lazy" alt={this.props.fullname} />;
        } else {
            blocPhoto = null;
        }

        const classNames = `person-bubble ${(this.props.className || "")} ${(this.props.onClick ? " clickable" : "")}`;

        return <div
            title={tooltip || ''}
            className={classNames}
            onClick={this.props.onClick ? () => this.props.onClick(this.props.id) : undefined}
        >
            <div className="initials bloc-accentbg"><span>{this.state.initials}</span></div>
            {blocPhoto}
            {/* <div className="photo" style={photo}></div> */}
        </div>;
    }
}
